<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_report.warehouse_details_report') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.division')"
                label-for="division_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_report.division')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                plain
                v-model="search.division_id"
                :options="divisionList"
                id="division_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_config.region')"
                    label-for="region_id"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                </template>
                    <b-form-select
                        plain
                        v-model="search.region_id"
                        :options="regionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_report.district')"
                    label-for="district_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_report.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="search.district_id"
                    :options="districtList"
                    id="district_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_report.upazilla')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                plain
                v-model="search.upazilla_id"
                :options="upazilaList"
                id="upazilla_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Union" vid="union_id" rules="">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('globalTrans.union')"
                    label-for="union_id"
                    slot-scope="{ valid, errors }"
                    >
                    <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse Type" vid="warehouse_type_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="warehouse_type_id"
                    :label="$t('warehouse_config.warehouse_type')"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="search.warehouse_type_id"
                    :options="warehouseTypeList"
                    id="warehouse_type_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse" vid="warehouse_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="commodity_group_id"
                    v-if="!authUser.warehouse_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-else
                >
                </b-form-select>
                    <div v-if="!authUser.warehouse_id" class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="8">
                <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
            <b-col></b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.warehouse_details_report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Warehouse Details Report Sheet"
                  name="warehouse-details-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                   <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.warehouse_details_report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th style="width:5%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.farmer_name') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.contact_number') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.address') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.stock_quantity') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.due_rent') }}</b-th>
                          </b-tr>
                        </b-thead>
                         <b-tbody  v-for="(info, mobileNo, index) in warehouseDetails" :key="index + 99">
                          <template v-for="(value, cropNameId, indx) in info" >
                                <b-tr :key="index + indx + 200" v-if="indx">
                                  <b-td>{{ typeof cropNameList.find(elem => elem.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(elem => elem.value == cropNameId).text : cropNameList.find(elem => elem.value == cropNameId).text_bn) : '' }}</b-td>
                                  <b-td class="text-right">{{ $n(value.stock_qty) }}</b-td>
                                  <b-td class="text-right">{{ $n(value.due_rent) }}</b-td>
                                </b-tr>
                                <b-tr :key="index + indx + 999" v-else class="dfs">
                                  <b-th :rowspan="Object.keys(info).length">{{ index+1 }}</b-th>
                                  <b-th :rowspan="Object.keys(info).length"><span class="capitalize">{{ typeof farmerList.find(item => item.mobile_no == mobileNo) != 'undefined' ? (currentLocale === 'en' ? farmerList.find(item => item.mobile_no == mobileNo).name : farmerList.find(item => item.mobile_no == mobileNo).name_bn) : '' }}</span></b-th>
                                  <b-th :rowspan="Object.keys(info).length"><span class="capitalize">{{ typeof farmerList.find(item => item.mobile_no == mobileNo) != 'undefined' ? ($n(0) + $n(farmerList.find(item => item.mobile_no == mobileNo).mobile_no, { useGrouping: false })) : '' }}</span></b-th>
                                  <b-th :rowspan="Object.keys(info).length"><span class="capitalize">{{ typeof farmerList.find(item => item.mobile_no == mobileNo) != 'undefined' ? (currentLocale === 'en' ? farmerList.find(item => item.mobile_no == mobileNo).address : farmerList.find(item => item.mobile_no == mobileNo).address_bn) : '' }}</span></b-th>
                                  <b-td :key="indx + 9999">{{ typeof cropNameList.find(elem => elem.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(elem => elem.value == cropNameId).text : cropNameList.find(elem => elem.value == cropNameId).text_bn) : '' }}</b-td>
                                  <b-td class="text-right">{{ $n(value.stock_qty) }}</b-td>
                                  <b-td class="text-right" :key="indx + 99999">{{ $n(value.due_rent) }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr variant="secondary" >
                            <b-th colspan="5" class="text-right">
                              {{ $t('sitePreference.total') }}
                            </b-th>
                            <b-th class="text-right">{{ $n(totalStockQty) }}</b-th>
                            <b-th class="text-right">{{ $n(totalDueRent) }}</b-th>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, warehouseDetailsListReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { mobileNumber } from '@/Utils/fliter'

pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  "Farmer's Name": 'farmer',
  'Contact No': 'contact_number',
  Address: 'address_info',
  'Crop Name': 'crop',
  'Stock Qty(Quintal)': 'stock_quantity_custom',
  'Due Rent(Tk)': 'due_rent_custom'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'কৃষকের নাম': 'farmer',
  'যোগাযোগের নম্বর': 'contact_number',
  ঠিকানা: 'address_info',
  'শস্য নাম': 'crop',
  'স্টক পরিমাণ(কুইন্টাল)': 'stock_quantity_custom',
  'বাকি ভাড়া(টাকা)': 'due_rent_custom'
}

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0
      },
      organization: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      warehouseDetails: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      farmerList: [],
      totalStockQty: 0,
      totalDueRent: 0,
      reportListData: [],
      districtLoading: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
      // var serial = 0
      const customizeData = this.reportListData.map(item => {
        if (this.currentLocale === 'bn') {
          item.farmer = item.farmer_name_bn
          item.address_info = item.address_bn
          item.crop = item.crop_name_bn
        } else {
          item.farmer = item.farmer_name
          item.address_info = item.address
          item.crop = item.crop_name
        }
        item.serial = item.serial_no ? this.$n(item.serial_no) : ''
        item.contact_number = item.contact_no ? mobileNumber(item.contact_no) : ''
        item.stock_quantity_custom = this.$n(item.stock_quantity)
        item.due_rent_custom = this.$n(item.due_rent)
        return Object.assign({}, item)
      })
      const item = {}
      item.crop = this.$t('sitePreference.total')
      item.stock_quantity_custom = this.$n(this.totalStockQty)
      item.due_rent_custom = this.$n(this.totalDueRent)
      customizeData.push(item)
      return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + ' ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id)
      return [this.$t('warehouse_report.warehouse_details_report'), '', firstRow, secondRow, '']
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return listObject
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    cropNameList: function () {
      return this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.districtList = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
  },
  created () {
    this.loadData()
    this.getFarmerList()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    searchData () {
      // if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      // }
      // this.loading = false
    },
    isEmpty (obj) {
      return Object.keys(Object.keys(obj).length === 0 && obj.constructor === Object).length === 0 && Object.keys(obj).length === 0 && obj.constructor === Object.constructor === Object
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      await RestApi.getData(warehouseServiceBaseUrl, warehouseDetailsListReport, this.search).then(response => {
        const listData = []
        if (response.success) {
          const finalData = []
          const obj = response.warehouseDetailsInfo
          let totalStockQty = 0
          let totalDueRent = 0
          if (!this.isEmpty(obj)) {
            let serial = 1
            for (const mobileNo in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, mobileNo)) {
                const itemArr = obj[mobileNo]
                const itemData = []
                let itemNo = 1
                for (const cropNameId in itemArr) {
                  if (Object.prototype.hasOwnProperty.call(itemArr, cropNameId)) {
                    const nestedItem = itemArr[cropNameId]
                    const listItem = {}
                    listItem.serial_no = itemNo === 1 ? serial : ''

                    const farmerObj = this.farmerList.find(item => item.mobile_no === mobileNo)
                    if (typeof farmerObj !== 'undefined' && itemNo === 1) {
                      listItem.farmer_name = farmerObj.name
                      listItem.contact_no = farmerObj.mobile_no
                      listItem.address = farmerObj.address
                      listItem.farmer_name_bn = farmerObj.name_bn
                      listItem.address_bn = farmerObj.address_bn
                    } else {
                      listItem.farmer_name = ''
                      listItem.contact_no = ''
                      listItem.address = ''
                      listItem.farmer_name_bn = ''
                      listItem.address_bn = ''
                    }
                    const cropNameObj = this.cropNameList.find(item => item.value === parseInt(cropNameId))
                    if (typeof cropNameObj !== 'undefined') {
                      listItem.crop_name = cropNameObj.text
                      listItem.crop_name_bn = cropNameObj.text_bn
                    } else {
                      listItem.crop_name = ''
                      listItem.crop_name_bn = ''
                    }
                    itemData[cropNameId] = nestedItem
                    totalStockQty += nestedItem.stock_qty
                    totalDueRent += nestedItem.due_rent
                    listItem.stock_quantity = nestedItem.stock_qty
                    listItem.due_rent = nestedItem.due_rent

                    listData.push(listItem)
                    itemNo++
                  }
                }
                finalData[mobileNo] = itemData
              }
              serial++
            }
          }
          this.warehouseDetails = response.warehouseDetailsInfo ? response.warehouseDetailsInfo : {}
          this.totalStockQty = totalStockQty
          this.totalDueRent = totalDueRent
          this.reportListData = listData
        } else {
          this.warehouseDetails = {}
          this.totalStockQty = 0
          this.totalDueRent = 0
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
      this.districtLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    async getFarmerList () {
        const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.farmer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.contact_number'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.address'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.stock_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.due_rent'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Details Report' : 'গুদাম বিস্তারিত প্রতিবেদন'
      const columnWids = ['7%', '20%', '20%', '20%', '10%', '10%', '10%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'warehouse-details-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'farmer_name' : 'farmer_name_bn' },
          { key: 'contact_no' },
          { key: this.$i18n.locale === 'en' ? 'address' : 'address_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'stock_quantity' },
          { key: 'due_rent' }
        ]
      // var serial = 0
      const listData = this.reportListData.map(item => {
        // serial += 1
        const rowData = keys.map((keyItem, index) => {
          if (keyItem.key === 'serial_no') {
              return { text: item[keyItem.key] ? this.$n(item[keyItem.key]) : '' }
          }
          if (['stock_quantity', 'due_rent'].includes(keyItem.key)) {
            return { text: this.$n(item[keyItem.key]) }
          }
          if (keyItem.key === 'contact_no') {
            const text = item[keyItem.key] ? this.$n(0) + this.$n(item[keyItem.key], { useGrouping: false }) : ''
            return { text: text }
          }

          return { text: item[keyItem.key] }
        })
        return rowData
      })

      const totalItem = [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        this.currentLocale === 'en' ? { text: 'Total :' } : { text: 'মোট :' },
        { text: this.$n(this.totalStockQty) },
        { text: this.$n(this.totalDueRent) }
      ]
      listData.push(totalItem)

      return listData
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
